<template>
  <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol"
    :style="{ 'backgroundColor': isEdit ? '#fff7e6' : '#ffffff' }">
    <a-form-model-item label="APPID" prop="configObj.appId" :label-col="{ span: 4 }" :wrapper-col="{ span: 6 }">
      <a-input :readOnly='!isEdit' v-model="form.configObj.appId" aria-readonly="true" placeholder="请输入APPID" />
    </a-form-model-item>
    <a-form-model-item label="开启状态" prop="statusChecked" :label-col="{ span: 4 }" :wrapper-col="{ span: 6 }">
      <!-- 如果是编辑状态，直接更改开关的值，不需要单独掉更新状态接口 -->
      <template v-if="isEdit">
        <a-switch v-model="form.statusChecked" @change="onChangeStatus" checked-children="开" un-checked-children="关" />
      </template>
      <!-- 如果不是编辑状态，是快捷操作开关按钮状态，此时需要有确认弹窗并掉接口更改状态 -->
      <template v-else>
        <a-popconfirm :disabled="form.id ? false : true" :title='`确定要${statusText}吗？`' ok-text="确定" cancel-text="取消"
          @confirm="statusConfirm" @cancel="statusCancel">
          <a-switch :disabled="form.id ? false : true" v-model="form.statusChecked" checked-children="开"
            un-checked-children="关" />
        </a-popconfirm>
        <span v-if="form.id" class="margin-left-sm text-orange">点击更改状态</span>
      </template>
    </a-form-model-item>
    <a-form-model-item label="公钥类型" prop="configObj.mode">
      <a-radio-group v-model="form.configObj.mode">
        <!-- @change="onChangeMode"
        <a-radio :value="1">
          公钥模式
        </a-radio> -->
        <a-radio :value="2">
          证书模式
        </a-radio>
      </a-radio-group>
    </a-form-model-item>
    <a-form-model-item label="商户私钥" prop="configObj.privateKey">
      <a-input v-if="isEdit" v-model="form.configObj.privateKey" type="textarea" placeholder="请获取商户私钥" />
      <template v-else>
        <a-input readOnly type="textarea" v-if="form.configObj.privateKey" placeholder="*************************" />
        <a-input readOnly type="textarea" v-else placeholder="请获取商户私钥证书" />
      </template>
    </a-form-model-item>
    <div v-show="form.configObj.mode === 1">
      <a-form-model-item label="支付宝公钥字符串" prop="configObj.alipayPublicKey">
        <a-input v-if="isEdit" v-model="form.configObj.alipayPublicKey" type="textarea" placeholder="请输入支付宝公钥字符串" />
        <template v-else>
          <a-input readOnly type="textarea" v-if="form.configObj.alipayPublicKey"
            placeholder="*************************" />
          <a-input readOnly type="textarea" v-else placeholder="请输入支付宝公钥字符串" />
        </template>
      </a-form-model-item>
    </div>
    <div v-show="form.configObj.mode === 2">
      <a-form-model-item label="商户公钥应用证书" prop="configObj.appCertContent">
        <a-input v-if="isEdit" readOnly v-model="form.configObj.appCertContent" type="textarea"
          placeholder="请获取商户公钥应用证书" />
        <template v-else>
          <a-input readOnly type="textarea" v-if="form.configObj.appCertContent"
            placeholder="*************************" />
          <a-input readOnly type="textarea" v-else placeholder="请获取商户私钥证书" />
        </template>
        <a-upload :multiple="false" :accept="fileAccept" @beforeUpload="beforeUpload" :customRequest="uploadAppCert"
          :showUploadList="false">
          <a-button v-show="isEdit" :readOnly='!isEdit' type="primary"> 点击获取 </a-button>
        </a-upload>
      </a-form-model-item>
      <a-form-model-item label="支付宝公钥证书" prop="configObj.alipayPublicCertContent">
        <a-input v-if="isEdit" readOnly v-model="form.configObj.alipayPublicCertContent" type="textarea"
          placeholder="请获取支付宝公钥证书" />
        <template v-else>
          <a-input readOnly type="textarea" v-if="form.configObj.alipayPublicCertContent"
            placeholder="*************************" />
          <a-input readOnly type="textarea" v-else placeholder="请获取支付宝公钥证书" />
        </template>
        <a-upload :multiple="false" :accept="fileAccept" @beforeUpload="beforeUpload" :customRequest="uploadAlipayPublic"
          :showUploadList="false">
          <a-button v-show="isEdit" :readOnly='!isEdit' type="primary"> 点击获取 </a-button>
        </a-upload>
      </a-form-model-item>
      <a-form-model-item label="根证书" prop="configObj.rootCertContent">
        <a-input v-if="isEdit" readOnly v-model="form.configObj.rootCertContent" type="textarea" placeholder="请上传根证书" />
        <template v-else>
          <a-input readOnly type="textarea" v-if="form.configObj.rootCertContent"
            placeholder="*************************" />
          <a-input readOnly type="textarea" v-else placeholder="请上传根证书" />
        </template>
        <a-upload :multiple="false" :accept="fileAccept" @beforeUpload="beforeUpload" :customRequest="uploadRootCert"
          :showUploadList="false">
          <a-button v-show="isEdit" :readOnly='!isEdit' type="primary"> 点击获取 </a-button>
        </a-upload>
      </a-form-model-item>
    </div>
    <a-row class="margin-top-xl">
      <a-col :span="4"></a-col>
      <a-col :span="14">
        <a-button v-show="isEdit" type='primary' size="large" @click="handleSubmit" :loading="isloading"
          style="width:200px;">
          保存
        </a-button>
      </a-col>
    </a-row>
  </a-form-model>
</template>
<script>
import * as paychannel from '@/api/pay/pay.js'

export default {
  props: {
    isEdit: {
      type: Boolean,
      default: false
    },
    type: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      isloading: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      form: {
        id: '',
        code: 'ALIPAY',
        config: '', // 渠道配置的 json 字符串
        configObj: {
          "appId": "",
          "mchId": "",
          "mode": 2,
          "signType": "RSA2",
          "privateKey": "",   // 商户私钥
          "alipayPublicKey": "",  // 支付宝公钥字

          "appCertContent": "", // 商户公钥应用证书
          "alipayPublicCertContent": "",  // 支付宝公钥字符串
          "rootCertContent": "" //根证书
        },
        status: 0,
        statusChecked: true
      },
      statusText: '关闭',
      fileAccept: ".crt",
      rules: {
        status: [
          { required: true, message: '请选择选择开启状态' },
        ],
        configObj: {
          appId: [
            { required: true, message: '请输入appId' }
          ],
          mchId: [
            { required: true, message: '请输入mchId' }
          ],
          privateKey: [
            { required: true, message: '请输入商户私钥' }
          ],
          appCertContent: [
            { required: true, message: '请获取商户公钥字符串' }
          ],
          alipayPublicCertContent: [
            { required: true, message: '请获取支付宝公钥字符串' }
          ],
          rootCertContent: [
            { required: true, message: '请获取根证书信息' }
          ],
        }
      },
      objDefault: {
        appId: [
          { required: true, message: '请输入appId' }
        ],
        mchId: [
          { required: true, message: '请输入mchId' }
        ],
        privateKey: [
          { required: true, message: '请输入商户私钥' }
        ],
        alipayPublicKey: [
          { required: true, message: '请输入支付宝公钥字' }
        ],
      },
      obj1: {
        privateKey: [
          { required: true, message: '请输入商户私钥' }
        ],
        alipayPublicKey: [
          { required: true, message: '请输入支付宝公钥字' }
        ],
      },
      obj2: {
        appCertContent: [
          { required: true, message: '请获取商户公钥应用证书' }
        ],
        alipayPublicCertContent: [
          { required: true, message: '请获取支付宝公钥字符串' }
        ],
        rootCertContent: [
          { required: true, message: '请获取根证书信息' }
        ],
      }
    }
  },
  watch: {
    isEdit(newVal) {
      // this.$refs.ruleForm.resetFields()
    },
    type(newVal) {
      if (newVal) {
        if (newVal === 1) {
          this.form.code = 'ALIPAY'
        //   // 验证规则rules.configObj obj1
        //   this.rules.configObj = this.obj1
        } else if (newVal === 2) {
          this.form.code = 'ALIPAY_PARTNER'
        //   // 验证规则rules.configObj obj1 + obj2
        //   this.rules.configObj = { ...this.obj1, ...this.obj2 }
        }
        this.$refs.ruleForm.resetFields()
        this.getInfo()
        this.$forceUpdate()
      }
    },
    statusChecked(newVal) {
      let str = '开启'
      if (newVal === true) {
        str = '关闭'
      } else if (newVal === false) {
        str = '开启'
      }
      this.statusText = str
      this.$forceUpdate()
    }
  },
  created() {
    this.getInfo()
  },
  methods: {
    /**
     * 根据id获取详情
     */
    getInfo() {
      // let code = ''
      // this.type === 1 ? code = 'ALIPAY' :
      //   this.type === 2 ? code = 'ALIPAY_PARTNER' : ''

      paychannel.detailByMId({
        code: this.form.code
      }).then(res => {
        if (res.success) {
          if (res.data.id) {
            let obj = res.data

            obj.statusChecked = true
            let str = ''
            /**1是开启状态  0是关闭状态 */
            if (obj.status === 1) {
              obj.statusChecked = true
              str = '关闭'
            } else if (obj.status === 0) {
              obj.statusChecked = false
              str = '开启'
            }
            this.$set(this.form, 'id', obj.id)
            this.$set(this.form, 'status', obj.status)
            this.$set(this.form, 'statusChecked', obj.statusChecked)
            this.statusText = str


            // obj.statusChecked = true
            // obj.status === 1 ? obj.statusChecked = false :
            //   obj.status === 0 ? obj.statusChecked = true : obj.statusChecked = true

            // this.$set(this.form, 'id', obj.id)
            // this.$set(this.form, 'status', obj.status)
            // this.$set(this.form, 'statusChecked', obj.statusChecked)
            if (obj.config) {
              let obj2 = JSON.parse(obj.config)
              for (var key in obj2) {
                this.$set(this.form.configObj, key, obj2[key])
              }
            }
          }else{
            this.resetForm()
          }
          /**根据切换的 公钥模式和证书模式 动态设置表单验证 */
          // let aobj = { ...this.objDefault, ...this.obj1 }
          // this.rules.configObj = aobj
          this.$forceUpdate()
        }
      })
    },
    /**确认 切换状态 */
    statusConfirm(e) {
      if (!this.form.id) return this.$message.error("您还没有支付宝支付渠道")
      let beforeStatus = !this.form.statusChecked // 改变前的状态
      let afterStatus = this.form.statusChecked   // 改变后的状态
      let status = 1, msg = ''
      if (afterStatus === true) {
        status = 1
        msg = '开启'
      } else if (afterStatus === false) {
        status = 0
        msg = '关闭'
      }
      paychannel.editStatus({
        id: this.form.id,
        status: status
      }).then(response => {
        this.loading = false;
        if (response.success) {
          this.$set(this.form, 'statusChecked', afterStatus)
          this.$set(this.form, 'status', status)
          afterStatus ? this.statusText = '关闭' : this.statusText = '开启'
          this.$message.success(msg + '成功')
        } else {
          /**  如果改变状态失败，重置为原来的状态  */
          this.$set(this.form, 'statusChecked', beforeStatus)
          afterStatus ? this.form.status = 1 : this.form.status = 0
          beforeStatus ? this.statusText = '关闭' : this.statusText = '开启'
          this.$message.error(msg + '失败')
        }
      })
    },
    /**确认 切换状态 */
    statusCancel() {
      let beforeStatus = !this.form.statusChecked // 改变前的状态
      this.$set(this.form, 'statusChecked', beforeStatus)
      // beforeStatus ? this.form.status === 1 : this.form.status === 0
    },
    /**switch
     * 切换商户号状态 开启或者关闭
     */
    onChangeStatus(e) {
      let status = 1
      e === true ? status = 1 :
        e === false ? status = 0 : ''
      this.form.status = status
    },
    handleSubmit(e) {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          let apiUrl = ''
          if (this.form.id) {
            apiUrl = paychannel.edit
          } else {
            apiUrl = paychannel.add
          }
          let data = JSON.parse(JSON.stringify(this.form))
          data.config = JSON.stringify(this.form.configObj);
          delete data.configObj
          delete data.statusChecked
          this.isloading = true
          apiUrl(data).then(res => {
            this.isloading = false
            if (res.success) {
              this.$message.success('操作成功')
            } else {
              this.$message.error(res.message)
            }
          }).catch(err => {
            this.isloading = false
          })
        } else {
          return false;
        }
      });
    },
    resetForm() {
      let code = ''
      if (this.type === 1) {
        code = 'ALIPAY'
      } else if (this.type === 2) {
        code = 'ALIPAY_PARTNER'
      }
      this.form = {
        id: '',
        code: code,
        config: '', // 渠道配置的 json 字符串
        configObj: {
          "appId": "",
          "mchId": "",
          "mode": 2,
          "signType": "RSA2",
          "privateKey": "",   // 商户私钥
          "alipayPublicKey": "",  // 支付宝公钥字

          "appCertContent": "", // 商户公钥应用证书
          "alipayPublicCertContent": "",  // 支付宝公钥字符串
          "rootCertContent": "" //根证书
        },
        status: 1,
        statusChecked: true
      }
      if (this.$refs['ruleForm'] !== undefined) {
        this.$nextTick(() => {
          this.$refs['ruleForm'].resetFields() // 清空数据
        })
      }
    },
    /****************start********************** 上传文件 ***************************************/
    beforeUpload() {
      let format = '.' + file.name.split(".")[1];
      if (format !== this.fileAccept) {
        this.$message.error('请上传指定格式"' + this.fileAccept + '"文件');
        return false;
      }
      let isRightSize = file.size / 1024 / 1024 < 2
      if (!isRightSize) {
        this.$message.error('文件大小超过 2MB')
      }
      return isRightSize
    },
    // alipayPublicCertBeforeUpload() { },
    // rootCertBeforeUpload() { },
    uploadAppCert(event) {
      const readFile = new FileReader()
      readFile.onload = (e) => {
        let result = e.target.result
        this.form.configObj.appCertContent = result
      }
      readFile.readAsText(event.file);
    },
    uploadAlipayPublic(event) {
      const readFile = new FileReader()
      readFile.onload = (e) => {
        let result = e.target.result
        this.form.configObj.alipayPublicCertContent = result
      }
      readFile.readAsText(event.file);
    },
    uploadRootCert(event) {
      const readFile = new FileReader()
      readFile.onload = (e) => {
        let result = e.target.result
        this.form.configObj.rootCertContent = result
      }
      readFile.readAsText(event.file);
    },
    /****************end********************** 上传文件 ***************************************/
    /**目前 默认用的是公钥模式，不用切换了，以下方法留作备份
     * 切换 公钥模式 证书模式
     */
    // onChangeMode(e) {
    //   console.log('eeeee', e)
    //   let type = e.target.value
    //   this.form.mode = type
    //   if (type === 1) {
    //     let obj = { ...this.objDefault, ...this.obj1 }
    //     this.rules.configObj = obj
    //   } else if (type === 2) {
    //     let obj = { ...this.objDefault, ...this.obj2 }
    //     this.rules.configObj = obj
    //   }
    // },
  }
}
</script>